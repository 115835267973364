export default {
	address: '0x32105017918Cb9CD9A5f21fd6984Ee7DC82B9E7E',
	abi: [
		{
			constant: true,
			inputs: [{ name: 'account', type: 'address' }],
			name: 'earned',
			outputs: [{ name: '', type: 'uint256' }],
			payable: false,
			stateMutability: 'view',
			type: 'function',
		},
		{
			constant: true,
			inputs: [{ name: '', type: 'address' }],
			name: 'rewards',
			outputs: [{ name: '', type: 'uint256' }],
			payable: false,
			stateMutability: 'view',
			type: 'function',
		},
		{
			constant: false,
			inputs: [{ name: '_rewardDistribution', type: 'address' }],
			name: 'setRewardDistribution',
			outputs: [],
			payable: false,
			stateMutability: 'nonpayable',
			type: 'function',
		},
		{
			constant: true,
			inputs: [],
			name: 'totalSupply',
			outputs: [{ name: '', type: 'uint256' }],
			payable: false,
			stateMutability: 'view',
			type: 'function',
		},
		{
			constant: true,
			inputs: [],
			name: 'DURATION',
			outputs: [{ name: '', type: 'uint256' }],
			payable: false,
			stateMutability: 'view',
			type: 'function',
		},
		{
			constant: false,
			inputs: [{ name: 'amount', type: 'uint256' }],
			name: 'withdraw',
			outputs: [],
			payable: false,
			stateMutability: 'nonpayable',
			type: 'function',
		},
		{
			constant: false,
			inputs: [{ name: 'reward', type: 'uint256' }],
			name: 'notifyRewardAmount',
			outputs: [],
			payable: false,
			stateMutability: 'nonpayable',
			type: 'function',
		},
		{
			constant: false,
			inputs: [],
			name: 'getReward',
			outputs: [],
			payable: false,
			stateMutability: 'nonpayable',
			type: 'function',
		},
		{
			constant: true,
			inputs: [{ name: 'account', type: 'address' }],
			name: 'balanceOf',
			outputs: [{ name: '', type: 'uint256' }],
			payable: false,
			stateMutability: 'view',
			type: 'function',
		},
		{
			constant: false,
			inputs: [],
			name: 'renounceOwnership',
			outputs: [],
			payable: false,
			stateMutability: 'nonpayable',
			type: 'function',
		},
		{
			constant: true,
			inputs: [],
			name: 'rewardRate',
			outputs: [{ name: '', type: 'uint256' }],
			payable: false,
			stateMutability: 'view',
			type: 'function',
		},
		{
			constant: true,
			inputs: [],
			name: 'lastTimeRewardApplicable',
			outputs: [{ name: '', type: 'uint256' }],
			payable: false,
			stateMutability: 'view',
			type: 'function',
		},
		{
			constant: true,
			inputs: [{ name: '', type: 'address' }],
			name: 'userRewardPerTokenPaid',
			outputs: [{ name: '', type: 'uint256' }],
			payable: false,
			stateMutability: 'view',
			type: 'function',
		},
		{
			constant: true,
			inputs: [],
			name: 'owner',
			outputs: [{ name: '', type: 'address' }],
			payable: false,
			stateMutability: 'view',
			type: 'function',
		},
		{
			constant: true,
			inputs: [],
			name: 'isOwner',
			outputs: [{ name: '', type: 'bool' }],
			payable: false,
			stateMutability: 'view',
			type: 'function',
		},
		{
			constant: false,
			inputs: [{ name: 'amount', type: 'uint256' }],
			name: 'stake',
			outputs: [],
			payable: false,
			stateMutability: 'nonpayable',
			type: 'function',
		},
		{
			constant: true,
			inputs: [],
			name: 'lastUpdateTime',
			outputs: [{ name: '', type: 'uint256' }],
			payable: false,
			stateMutability: 'view',
			type: 'function',
		},
		{
			constant: true,
			inputs: [],
			name: 'rewardPerToken',
			outputs: [{ name: '', type: 'uint256' }],
			payable: false,
			stateMutability: 'view',
			type: 'function',
		},
		{
			constant: true,
			inputs: [],
			name: 'rewardPerTokenStored',
			outputs: [{ name: '', type: 'uint256' }],
			payable: false,
			stateMutability: 'view',
			type: 'function',
		},
		{
			constant: true,
			inputs: [],
			name: 'snx',
			outputs: [{ name: '', type: 'address' }],
			payable: false,
			stateMutability: 'view',
			type: 'function',
		},
		{
			constant: false,
			inputs: [],
			name: 'exit',
			outputs: [],
			payable: false,
			stateMutability: 'nonpayable',
			type: 'function',
		},
		{
			constant: true,
			inputs: [],
			name: 'periodFinish',
			outputs: [{ name: '', type: 'uint256' }],
			payable: false,
			stateMutability: 'view',
			type: 'function',
		},
		{
			constant: true,
			inputs: [],
			name: 'uni',
			outputs: [{ name: '', type: 'address' }],
			payable: false,
			stateMutability: 'view',
			type: 'function',
		},
		{
			constant: false,
			inputs: [{ name: 'newOwner', type: 'address' }],
			name: 'transferOwnership',
			outputs: [],
			payable: false,
			stateMutability: 'nonpayable',
			type: 'function',
		},
		{
			anonymous: false,
			inputs: [{ indexed: false, name: 'reward', type: 'uint256' }],
			name: 'RewardAdded',
			type: 'event',
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, name: 'user', type: 'address' },
				{ indexed: false, name: 'amount', type: 'uint256' },
			],
			name: 'Staked',
			type: 'event',
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, name: 'user', type: 'address' },
				{ indexed: false, name: 'amount', type: 'uint256' },
			],
			name: 'Withdrawn',
			type: 'event',
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, name: 'user', type: 'address' },
				{ indexed: false, name: 'reward', type: 'uint256' },
			],
			name: 'RewardPaid',
			type: 'event',
		},
		{
			anonymous: false,
			inputs: [
				{ indexed: true, name: 'previousOwner', type: 'address' },
				{ indexed: true, name: 'newOwner', type: 'address' },
			],
			name: 'OwnershipTransferred',
			type: 'event',
		},
	],
};
